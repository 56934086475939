<template>
  <div>
    <div class="d-flex justify-content-between">
      <b-row>
        <b-col
          :md="12"
          class="d-flex flex-wrap flex-row"
        >
          <el-date-picker
            v-model="filterModel.from_date"
            class="w-85"
            type="datetime"
            placement="bottom-start"
            :picker-options="pickerOption"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="dd.MM.yyyy HH:mm:ss"
            :placeholder="$t('Дата начала')"
          />
          <el-date-picker
            v-model="filterModel.to_date"
            class="w-85"
            type="datetime"
            placement="bottom-start"
            :picker-options="pickerOption"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="dd.MM.yyyy HH:mm:ss"
            :placeholder="$t('Дата окончания')"
          />
          <!-- <x-date-picker
            v-model="filterModel.from_date"
            :placeholder="$t('Дата начала')"
            class="mr-1 mb-1"
          /> -->
          <!-- <x-date-picker
            v-model="filterModel.to_date"
            :placeholder="$t('Дата окончания')"
            class="mr-1 mb-1"
          /> -->
        </b-col>
      </b-row>
      <b-overlay :show="loadingBtn">
        <b-button
          variant="outline-success"
          class="float-right"
          size="small"
          @click="tableToExcel"
        >
          <img
            src="/images/excel_1.svg"
            alt=""
          >
          Yuklab olish
        </b-button>
      </b-overlay>
    </div>

    <b-overlay :show="loading">
      <good-table-column-search
        :items="items.data"
        :columns="columns"
        :total="items.total"
        :page="page"
        :filter="filterModel"
        @getItems="getItems"
        @onPageChange="(p) => (page = p)"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          {{ props }}
        </template>
        <template
          slot="table-column"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'sourcee'">
            <b-badge
              v-if="props.row.source_id == 1"
              pill
              variant="light-warning"
            >{{ props.row.source }}</b-badge>
            <b-badge
              v-else
              pill
              variant="light-info"
            >{{ props.row.source }}</b-badge>
          </span>
        </template>
      </good-table-column-search>

      <!-- excel table -->
      <good-table-column-search
        v-if="itemsX"
        v-show="false"
        :items="itemsX.data"
        class="tablee"
        :columns="columns"
        :total="itemsX.total"
        :filter="filterModel"
      >
        <template
          slot="table-column"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'sourcee'">
            {{ props.row.source }}
          </span>
        </template>
      </good-table-column-search>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { showToast } from '@/utils/toast'
import GoodTableColumnSearch from '@/views/table/vue-good-table/GoodTableColumnSearch.vue'

export default {
  name: 'Index',
  components: {
    GoodTableColumnSearch,
  },
  data() {
    return {
      page: 1,
      loading: false,
      items: [],
      itemsX: [],
      loadingBtn: false,
      filterModel: {
        per_page: 50,
        number: null,
        address: null,
        region_id: null,
        relations: 'role|region',
        appends: 'source',
        city_id: null,
        statusValue: 1,
        from_date: null,
        to_date: null,
        is_system_user: 0,
      },
      pickerOption: {
        firstDayOfWeek: 1,
      },
      //
      uri: 'data:application/vnd.ms-excel;base64,',
      template:
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta charset="utf-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64(s) {
        return window.btoa(unescape(encodeURIComponent(s)))
      },
      format(s, c) {
        return s.replace(/{(\w+)}/g, (m, p) => c[p])
      },
    }
  },
  computed: {
    // ...mapGetters({
    //   items: 'user/GET_ITEMS',
    // }),
    columns() {
      return [
        {
          label: '#',
          field: 'row_number',
        },
        {
          label: 'Ism Familiya',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'Telefon raqami',
          field: 'phone',
          // filterOptions: {
          //   enabled: true,
          //   placeholder: '',
          // },
        },
        {
          label: 'Hudud',
          field: 'region.name_uz',
        },
        {
          label: 'Tug`ilgan sana',
          field: 'birth_date',
          isDate: true,
        },
        {
          label: 'Ro`yxatdan o`tgan sana',
          field: 'created_at',
          isDateTime: true,
        },
        {
          label: 'Manba',
          field: 'sourcee',
        },

      ]
    },
  },
  watch: {
    page(newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
    'filterModel.per_page': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
    'filterModel.from_date': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
    'filterModel.to_date': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
  },
  mounted() {
    if (!this.hasPermission('settings.users-index')) {
      this.$router.push('/')
    }
    this.getItems()
  },
  methods: {
    ...mapActions({
      getItemsAction: 'user/index',
    }),
    async getItems() {
      this.loading = true
      await this.getItemsAction({ ...this.filterModel, page: this.page })
        .then(res => {
          this.items = res.data
        })
      this.loading = false
    },
    async getItemsX() {
      this.loadingBtn = true
      await this.getItemsAction({
        ...this.filterModel, per_page: 9999,
      }).then(res => {
        this.itemsX = res.data
      })
      this.loadingBtn = false
    },
    async tableToExcel(table) {
      this.loadingBtn = true
      await this.getItemsX()

      const tableeee = document.querySelector(
        'div.tablee div.vgt-responsive table',
      )
      const secondRow = tableeee.querySelector('thead tr:nth-child(2)')
      if (secondRow) {
        secondRow.remove()
      }
      setTimeout(() => {
        if (!table.nodeType) table = tableeee
        const ctx = {
          worksheet: `Ilova foydalanuvchilari ro'yhati (${this.todayDate})`,
          table: table.innerHTML,
        }
        const link = document.createElement('a')
        link.download = `Ilova foydalanuvchilari ro'yhati (${this.todayDate}).xls`
        link.href = this.uri + this.base64(this.format(this.template, ctx))
        link.click()
        this.loadingBtn = false
      }, 100)
    },
  },
}
</script>

<style scoped></style>
